import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { NotFoundPage } from "../lib";
import { PlacementChatPage } from "../PlacementChats/PlacementChatPage";
import { PlacementDetailsPage } from "./PlacementDetailsPage";

export function PlacementRoutes(): ReactElement {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.url}/:placementId/placement-candidate/:placementCandidateId`}>
        <PlacementDetailsPage />
      </Route>
      <Route path={`${match.url}/:placementId/workplace/:workplaceId/chat`}>
        <PlacementChatPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
