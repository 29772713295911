// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Stack, ThemeProvider } from "@mui/material";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEqual } from "lodash";

import { APP_V2_APP_EVENTS } from "../lib";
import { logEvent } from "../lib/analytics";
import { PullToRefresh } from "../lib/PullToRefresh/PullToRefresh";
import { useLocalStorage } from "../lib/utils";
import { useDebounce } from "../lib/utils/useDebounce";
import { useScrollRestoration } from "../lib/utils/useScrollRestoration";
import { IntroductionVideoCallout } from "../PlacementCandidate/components/IntroductionVideoCallout";
import { PlacementCandidateProfileUpdateDialog } from "../PlacementCandidate/components/PlacementCandidateProfileUpdateDialog";
import { type PlacementCandidate, PlacementCandidateStatus } from "../PlacementCandidate/types";
import { Callout } from "../ShiftDiscovery/components/Callout";
import { CbhIcon } from "../ShiftDiscovery/components/CbhIcon";
import { HorizontalScrollView } from "../ShiftDiscovery/components/HorizontalScrollView";
import { getShiftDiscoveryTheme } from "../ShiftDiscovery/theming/theme";
import {
  transformResponseIntoPlacement,
  useFetchPaginatedPlacements,
} from "./api/useFetchPaginatedPlacements";
import { FilterButton } from "./components/FilterButton";
import { PlacementCardItem } from "./components/PlacementCardItem";
import { PlacementsLoader } from "./components/PlacementsLoader";
import { SearchField } from "./components/SearchField";
import { type GetPlacementsForWorkplaceQuery } from "./types/fetchPlacements.schema";
import { buildPlacementsFilter } from "./utils";

interface PlacementsPageProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementsPage(props: PlacementsPageProps) {
  const { placementCandidate, worker } = props;
  const updateCandidateProfileModalState = useModalState();
  useScrollRestoration({ key: "jobs-tab", containerId: "jobs-list-container" });
  const [filter, setFilter] = useLocalStorage<GetPlacementsForWorkplaceQuery["filter"]>(
    "jobs-filter",
    {}
  );
  const [searchByWorkplaceName, setSearchByWorkplaceName] = useLocalStorage<string>(
    "jobs-search-workplace-name",
    ""
  );
  const [showApplications, setShowApplications] = useLocalStorage<boolean>(
    "jobs-show-applications",
    false
  );
  const debouncedSearchByWorkplaceName = useDebounce({
    value: searchByWorkplaceName,
    debounceTimeInMs: 1000,
    onUpdateValue: (value) => {
      setSearchByWorkplaceName(value);
    },
  });

  const {
    data: placementsData,
    isLoading: isPlacementsLoading,
    isSuccess: isPlacementsSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchPlacements,
  } = useFetchPaginatedPlacements(
    {
      workerId: worker.userId,
      placementCandidateId: placementCandidate.id,
      filter: buildPlacementsFilter({
        filter,
        showApplications,
        worker,
        searchByWorkplaceName: debouncedSearchByWorkplaceName,
      }),
    },
    {
      enabled:
        isDefined(worker.userId) &&
        placementCandidate.status === PlacementCandidateStatus.ACTIVATED,
    }
  );
  const nonEmptyFilter = Object.fromEntries(
    Object.entries(filter ?? {}).filter(([_, value]) => value)
  );

  return (
    <>
      <ThemeProvider theme={getShiftDiscoveryTheme()}>
        <PullToRefresh onRefresh={refetchPlacements} />
        <Box
          id="jobs-list-container"
          sx={{
            overflowY: "auto",
            paddingBottom: 12,
            backgroundColor: (theme) => theme.palette.background.primary,
            flex: 1,
          }}
        >
          <HorizontalScrollView
            stretch
            sx={{ padding: 5 }}
            onTouchStart={(event) => {
              event.stopPropagation();
            }}
            onTouchEnd={(event) => {
              event.stopPropagation();
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onMouseUp={(event) => {
              event.stopPropagation();
            }}
          >
            <IntroductionVideoCallout placementCandidate={placementCandidate} worker={worker} />
            <Callout
              title="Update profile"
              text="View or edit your job profile"
              iconType="passkeys"
              sx={(theme) => ({
                backgroundColor: theme.palette.info.light,
              })}
              onClick={() => {
                logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PROFILE_UPDATE_CLICKED);
                updateCandidateProfileModalState.openModal();
              }}
            />
          </HorizontalScrollView>

          <Stack spacing={6} sx={{ paddingX: 5 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text semibold variant="h4">
                {showApplications ? "Applications" : "Open Jobs"}
              </Text>
              <Button
                startIcon={<CbhIcon type={showApplications ? "new" : "plane"} size="xSmall" />}
                variant="outlined"
                size="small"
                sx={{
                  background: (theme) => theme.palette.background.tertiary,
                  "&:hover": {
                    background: (theme) => theme.palette.background.tertiary,
                  },
                }}
                onClick={() => {
                  setShowApplications(!showApplications);
                }}
              >
                {showApplications ? "Open Jobs" : "Applications"}
              </Button>
            </Stack>
            <Box>
              <SearchField value={searchByWorkplaceName} onChange={setSearchByWorkplaceName} />

              {!isEqual(nonEmptyFilter, {}) && (
                <Stack direction="row" justifyContent="flex-start">
                  <Button
                    size="small"
                    variant="text"
                    sx={{
                      paddingX: 2,
                      textDecoration: "underline",
                      textDecorationColor: (theme) => theme.palette.border?.primary,
                      textUnderlineOffset: 6,
                    }}
                    onClick={() => {
                      setFilter({});
                      logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                        placementCandidateId: placementCandidate.id,
                        action: "filters_cleared",
                        filters: { ...filter, workplaceName: debouncedSearchByWorkplaceName },
                      });
                    }}
                  >
                    Clear filters to view all {showApplications ? "applied jobs" : "jobs"}
                  </Button>
                </Stack>
              )}
            </Box>

            {isPlacementsLoading && <PlacementsLoader />}

            {isPlacementsSuccess && (placementsData.pages[0]?.data.length ?? 0) === 0 && (
              <Stack justifyContent="center" flex={1} alignItems="center">
                <Text variant="body1">
                  {showApplications
                    ? "You have not applied to any jobs yet!"
                    : "No relevant jobs listed yet!"}
                </Text>
              </Stack>
            )}

            {isDefined(placementsData) && placementsData.pages.length > 0 && (
              <Stack direction="column" spacing={6}>
                {placementsData?.pages.flatMap((placementPage, pageIndex) => {
                  return placementPage.data.map((placement, placementIndex) => {
                    const placementWithDetails = transformResponseIntoPlacement(
                      placement,
                      placementPage.included
                    );
                    return (
                      <PlacementCardItem
                        key={placementWithDetails.id}
                        placement={placementWithDetails}
                        placementCandidateId={placementCandidate.id}
                        pageIndex={pageIndex}
                        placementIndex={placementIndex}
                      />
                    );
                  });
                })}
              </Stack>
            )}

            {hasNextPage && (
              <Stack direction="row" justifyContent="center">
                <LoadingButton
                  sx={{ mb: 10 }}
                  isLoading={isFetchingNextPage}
                  onClick={async () => {
                    await fetchNextPage();
                    logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_ACTIONS, {
                      placementCandidateId: placementCandidate.id,
                      filters: { ...filter, workplaceName: debouncedSearchByWorkplaceName },
                      action: "load_more_clicked",
                    });
                  }}
                >
                  Load more
                </LoadingButton>
              </Stack>
            )}
          </Stack>
          <FilterButton
            filter={filter}
            setFilter={setFilter}
            worker={worker}
            placementCandidateId={placementCandidate.id}
            searchByWorkplaceName={debouncedSearchByWorkplaceName}
          />
        </Box>
      </ThemeProvider>
      {updateCandidateProfileModalState.modalIsOpen && (
        <PlacementCandidateProfileUpdateDialog
          updateCandidateProfileModalState={updateCandidateProfileModalState}
          placementCandidate={placementCandidate}
          worker={worker}
        />
      )}
    </>
  );
}
